<script setup lang="ts">
const { closeModal, setModalBusy, isVisible, isBusy } =
  useGlobalModalController()
</script>

<template>
  <div>
    <BaseModal
      title="Set up direct debit"
      size="xl"
      :show="isVisible('direct-debit-setup')"
      :can-close="!isBusy('direct-debit-setup')"
      @close-modal="closeModal('direct-debit-setup')"
    >
      <ModulesCreditFacilityAirWallexDebitSetup
        class="mt-4"
        @is-busy="(val) => setModalBusy('direct-debit-setup', val)"
        @close="closeModal('direct-debit-setup')"
        @success="
          () => {
            // openModal('direct-debit-connection-successful')
            closeModal('direct-debit-setup')
          }
        "
      />
    </BaseModal>
  </div>
</template>
